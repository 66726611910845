import React from "react";
export const Post = (props) => {
  const { title, username, content } = props.data;
  return (
    <div className="post-card">
      <h2>{title}</h2>
      <p className="post-username">{username}</p>
      <p className="post-content">{content}</p>
    </div>
  );
};
