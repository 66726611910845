import "./App.css";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Posts } from "./pages/Posts";
import { Home } from "./pages/Home";
import { CreatePost } from "./pages/CreatePost";
function App() {
  return (
    <>
      <Router>
        <div>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/all-posts">Posts</Link>
            </li>
            <li>
              <Link to="/post">Post</Link>
            </li>
          </ul>

          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/post">
              <CreatePost />
            </Route>
            <Route exact path="/all-posts">
              <Posts />
            </Route>
          </Switch>
        </div>
      </Router>
      <footer>
        Made With ❤️ By{" "}
        <a
          href="https://burhanuddinmerchant.ml"
          target="_blank"
          rel="noreferrer"
        >
          Burhanuddin Merchant
        </a>
      </footer>
    </>
  );
}

export default App;
