import React from "react";
import hero from "../svg/hero.svg";
import { Link } from "react-router-dom";
import "../css/Home.css";
export const Home = () => {
  return (
    <div className="hero">
      <h1>Welcome To The Cloud Post</h1>
      <p>
        A Social Media Platform Powered by{" "}
        <a
          href="https://workers.cloudflare.com/"
          target="_blank"
          rel="noreferrer"
        >
          Cloudflare Workers
        </a>
      </p>
      <img src={hero} alt="Hero" />
      <div className="hero-buttons">
        <Link to="/all-posts">
          <button>EXPLORE</button>
        </Link>
        <Link to="/post">
          <button>POST</button>
        </Link>
      </div>
    </div>
  );
};
