import React, { useState } from "react";
import "../css/CreatePost.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
export const CreatePost = () => {
  const [formState, setformState] = useState({
    username: "",
    title: "",
    content: "",
  });
  const handleChange = (e) => {
    setformState({ ...formState, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      title: formState.title,
      username: formState.username,
      content: formState.content,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      // redirect: "follow",
    };

    fetch(process.env.REACT_APP_API_BASE, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        NotificationManager.success("POSTED SUCCESSFULLY");
        setformState(() => ({ username: "", title: "", content: "" }));
      })
      .catch((error) => {
        NotificationManager.success("An Error Occured");
        setformState(() => ({ username: "", title: "", content: "" }));
      });
  };
  return (
    <div className="create-post">
      <h1>Create Post</h1>
      <form onSubmit={handleSubmit}>
        <input
          placeholder="username"
          name="username"
          required
          value={formState.username}
          onChange={handleChange}
        />
        <input
          placeholder="title"
          name="title"
          required
          value={formState.title}
          onChange={handleChange}
        />
        <textarea
          placeholder="content"
          name="content"
          value={formState.content}
          required
          onChange={handleChange}
        />
        <button>Post</button>
      </form>
      <NotificationContainer />
    </div>
  );
};
