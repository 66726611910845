import React, { useState, useEffect } from "react";
import { Post } from "../components/Post";
import "../css/Post.css";
import { Link } from "react-router-dom";

export const Posts = () => {
  const [data, setData] = useState({
    loading: true,
    posts: [],
    error: null,
  });
  useEffect(() => {
    const getPosts = () => {
      fetch(process.env.REACT_APP_API_BASE)
        .then((res) => res.json())
        .then((posts) => {
          setData({ loading: false, posts: posts.data, error: null });
        })
        .catch((e) => {
          setData({ loading: false, posts: [], error: "An Error Occured!" });
        });
    };
    getPosts();
  }, []);
  return (
    <div className="post-container">
      <h1 className="posts-header">POSTS</h1>
      {data.posts.length === 0 ? (
        <h2 className="error">
          No Posts Available! Be The First to <Link to="/post">Post</Link>
        </h2>
      ) : (
        ""
      )}
      {data.posts.map((p, i) => {
        return <Post data={p} key={i} />;
      })}
    </div>
  );
};
